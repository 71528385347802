@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: 'CalSans-SemiBold';
  src: url('./fonts/CalSans-SemiBold.woff') format('woff'),
    url('./fonts/CalSans-SemiBold.otf') format('opentype');
  font-weight: 600;
  font-display: swap;
  font-style: normal;
}

/* DM Sans Regular */
@font-face {
  font-family: 'DM Sans';
  src: url('./fonts/DM_Sans/DMSans-Regular.ttf') format('truetype');
  font-weight: 400;
  font-display: swap;
  font-style: normal;
}

/* DM Sans Italic */
@font-face {
  font-family: 'DM Sans';
  src: url('./fonts/DM_Sans/DMSans-Italic.ttf') format('truetype');
  font-weight: 400;
  font-display: swap;
  font-style: italic;
}

/* DM Sans Medium */
@font-face {
  font-family: 'DM Sans';
  src: url('./fonts/DM_Sans/DMSans-Medium.ttf') format('truetype');
  font-weight: 500;
  font-display: swap;
  font-style: normal;
}

/* DM Sans Bold */
@font-face {
  font-family: 'DM Sans';
  src: url('./fonts/DM_Sans/DMSans-Bold.ttf') format('truetype');
  font-weight: 700;
  font-display: swap;
  font-style: normal;
}

/* DM Sans Bold Italic */
@font-face {
  font-family: 'DM Sans';
  src: url('./fonts/DM_Sans/DMSans-BoldItalic.ttf') format('truetype');
  font-weight: 700;
  font-display: swap;
  font-style: italic;
}

/* Space Grotesk */
@font-face {
  font-family: 'Space Grotesk';
  src: url('./fonts/Space_Grotesk/SpaceGrotesk-VariableFont_wght.ttf')
    format('opentype');
  font-weight: 400;
  font-display: swap;
  font-style: normal;
}

@font-face {
  font-family: 'ChakraPetch-Bold';
  src: local('ChakraPetch-Bold'),
    url('./fonts/Chakra_Petch/ChakraPetch-Bold.ttf') format('truetype');
  font-weight: 700;
  font-display: swap;
  font-style: normal;
}

@font-face {
  font-family: 'ChakraPetch-BoldItalic';
  src: local('ChakraPetch-BoldItalic'),
    url('./fonts/Chakra_Petch/ChakraPetch-BoldItalic.ttf') format('truetype');
}

@font-face {
  font-family: 'ChakraPetch-Italic';
  src: local('ChakraPetch-Italic'),
    url('./fonts/Chakra_Petch/ChakraPetch-Italic.ttf') format('truetype');
}

@font-face {
  font-family: 'ChakraPetch-Light';
  src: local('ChakraPetch-Light'),
    url('./fonts/Chakra_Petch/ChakraPetch-Light.ttf') format('truetype');
}

@font-face {
  font-family: 'ChakraPetch-LightItalic';
  src: local('ChakraPetch-LightItalic'),
    url('fonts/Chakra_Petch/ChakraPetch-LightItalic.ttf') format('truetype');
}

@font-face {
  font-family: 'ChakraPetch-Medium';
  src: local('ChakraPetch-Medium'),
    url('./fonts/Chakra_Petch/ChakraPetch-Medium.ttf') format('truetype');
}

@font-face {
  font-family: 'ChakraPetch-MediumItalic';
  src: local('ChakraPetch-MediumItalic'),
    url('./fonts/Chakra_Petch/ChakraPetch-MediumItalic.ttf') format('truetype');
}

@font-face {
  font-family: 'ChakraPetch-Regular';
  src: local('ChakraPetch-Regular'),
    url('./fonts/Chakra_Petch/ChakraPetch-Regular.ttf') format('truetype');
}

@font-face {
  font-family: 'ChakraPetch-SemiBold';
  src: local('ChakraPetch-SemiBold'),
    url('./fonts/Chakra_Petch/ChakraPetch-SemiBold.ttf') format('truetype');
}

@font-face {
  font-family: 'ChakraPetch-SemiBoldItalic';
  src: local('ChakraPetch-SemiBoldItalic'),
    url('./fonts/Chakra_Petch/ChakraPetch-SemiBoldItalic.ttf')
      format('truetype');
}

body {
  -webkit-font-smoothing: subpixel-antialiased;
  background-color: #17171d;
}

/* clears the ‘X’ from Internet Explorer */
input[type='search']::-ms-clear {
  @apply hidden h-0 w-0;
}
input[type='search']::-ms-reveal {
  @apply hidden h-0 w-0;
}
/* clears the ‘X’ from Chrome */
input[type='search']::-webkit-search-decoration,
input[type='search']::-webkit-search-cancel-button,
input[type='search']::-webkit-search-results-button,
input[type='search']::-webkit-search-results-decoration {
  @apply hidden;
}

/* Cursor pointer for file upload */
::-webkit-file-upload-button {
  @apply cursor-pointer;
}

/* Scrollbar */
.scrollbar-custom {
  scrollbar-width: thin;
}

.scrollbar-custom::-webkit-scrollbar {
  overflow: overlay;
  @apply h-2 w-2;
}

.scrollbar-custom::-webkit-scrollbar-track {
  @apply bg-jacarta-100 dark:bg-jacarta-800;
}

.scrollbar-custom::-webkit-scrollbar-thumb {
  @apply rounded-lg bg-jacarta-300 dark:bg-jacarta-600;
}

/* Dropdowns */
.dropdown--is-open + .js-dropdown {
  visibility: visible;
  opacity: 1;
  transform: translateY(0);
}

.dropdown-menu.show {
  @apply block;
}

.dropdown-toggle > i,
.dropdown-toggle > button {
  @apply transition-transform;
}

.dropdown-toggle.show > i,
.dropdown-toggle.show > button {
  @apply rotate-180;
}

.dropdown-menu-end {
  --bs-position: end;
}

/* Mobile Menu */
.nav-menu--is-open {
  @apply visible flex flex-col items-start overflow-y-auto px-6 pb-6 pt-0 opacity-100;
}

.nav-open-noscroll {
  @apply h-screen overflow-hidden;
}

/* Slider */
.swiper-button-prev:after,
.swiper-rtl .swiper-button-next:after {
  @apply text-[0] content-['prev'];
}

.swiper-button-next:after,
.swiper-rtl .swiper-button-prev:after {
  @apply text-[0] content-['next'];
}

.swiper-button-disabled {
  @apply hidden;
}

/* Lzyload Preloader */
.swiper-lazy-preloader {
  @apply absolute left-1/2 top-1/2 z-10 -ml-[21px] -mt-[21px] box-border h-[42px] w-[42px] origin-center animate-spin rounded-full border-4 border-accent border-t-transparent;
}

/* Full slider with thumbs */
.full-slider-thumbs .swiper-slide-thumb-active {
  @apply bg-white/10;
}

.swiper-slide-thumb-active .progress {
  @apply w-full transition-width duration-[5000ms] ease-linear;
}

/* Tooltips */
.tippy-box[data-theme~='xhibiter'] {
  @apply bg-jacarta-700;
}

.tippy-box[data-theme~='xhibiter'][data-placement^='top']
  > .tippy-arrow::before {
  @apply border-t-jacarta-700;
}
.tippy-box[data-theme~='xhibiter'][data-placement^='bottom']
  > .tippy-arrow::before {
  @apply border-b-jacarta-700;
}
.tippy-box[data-theme~='xhibiter'][data-placement^='left']
  > .tippy-arrow::before {
  @apply border-l-jacarta-700;
}
.tippy-box[data-theme~='xhibiter'][data-placement^='right']
  > .tippy-arrow::before {
  @apply border-r-jacarta-700;
}

/* Sticky Header */
.js-page-header--is-sticky {
  @apply bg-white/50;
}

.js-page-header--is-sticky.page-header--transparent,
.dark .js-page-header--is-sticky {
  @apply bg-jacarta-700/50;
}

/* Tabs */
.nav-link.active {
  @apply text-jacarta-700 after:absolute after:inset-x-0 after:-bottom-px after:block after:h-[2px] after:w-full after:bg-accent dark:text-white dark:hover:text-white;
}

.tab-content > .tab-pane {
  @apply hidden;
}

.tab-content > .active {
  @apply block;
}

/* Modals */
.modal {
  @apply fixed top-0 left-0 z-50 hidden h-full w-full overflow-y-auto overflow-x-hidden outline-0;
}

.modal-dialog {
  @apply pointer-events-none relative my-12 mx-auto w-auto;
}

.modal-dialog-centered {
  @apply flex items-center;
}

.modal.fade .modal-dialog {
  @apply translate-x-0 -translate-y-14 transition-transform duration-300 will-change-transform;
}

.modal.show .modal-dialog {
  @apply transform-none;
}

.modal-content {
  @apply pointer-events-auto relative mx-4 flex flex-col rounded-2xl bg-white bg-clip-padding outline-0 dark:bg-jacarta-700;
}

.modal-header {
  @apply flex shrink-0 items-center justify-between border-b border-jacarta-100 p-6 dark:border-jacarta-600;
}

.modal-title {
  @apply mb-0 mt-1 font-display text-xl font-semibold leading-normal text-jacarta-700 dark:text-white;
}

.btn-close {
  @apply absolute right-6 border-0 bg-transparent transition-transform hover:rotate-180;
}

.modal-body {
  @apply relative flex-auto;
}

.modal-footer {
  @apply flex shrink-0 flex-wrap items-center justify-center border-t border-jacarta-100 p-6 dark:border-jacarta-600;
}

.modal-backdrop {
  @apply fixed top-0 left-0 z-40 h-screen w-screen bg-jacarta-900;
}

.modal-backdrop.fade {
  @apply opacity-0;
}

.modal-backdrop.show {
  @apply opacity-75;
}

/* Lightbox Modal */
.lightbox .modal-dialog {
  @apply flex min-h-[calc(100%_-_6rem)] max-w-5xl;
}

.lightbox .modal-content {
  @apply w-full overflow-hidden;
}

.lightbox .ratio-16x9 {
  --bs-aspect-ratio: calc(9 / 16 * 100%);
}

.ratio {
  @apply relative before:block before:pt-[var(--bs-aspect-ratio)];
}

.ratio > * {
  @apply absolute top-0 left-0 h-full w-full;
}

.lightbox .btn-close {
  @apply right-2 top-2 rounded-full !bg-jacarta-900/30;
}

.lightbox .btn-close svg {
  @apply !top-0 h-3 w-3;
}

/* Accordions */
.accordion-button {
  overflow-anchor: none;
}

.accordion-button:not(.collapsed) .accordion-arrow {
  @apply -rotate-180;
}

/* Transitions: Fade / Collapse */
.fade {
  @apply transition-opacity ease-linear;
}

.collapse:not(.show) {
  @apply hidden;
}

.collapsing {
  @apply h-0 overflow-hidden transition-height;
}

/* Likes */
.js-likes--active svg {
  @apply opacity-0;
}

.js-likes--active::before {
  @apply !opacity-100;
}

/* Article */
.article-content > *:not(.article-content-wide) {
  @apply mx-auto mt-6 max-w-[48.125rem];
}

.article-content > h1,
.article-content > h2,
.article-content > h3,
.article-content > h4,
.article-content > h5,
.article-content > h6 {
  @apply !mt-12 mb-4 font-display text-jacarta-700 dark:text-white;
}

.article-content > p {
  @apply dark:text-jacarta-300;
}

/* Alerts */
.alert-error {
  @apply bg-red/10 text-red;
}

.alert-success {
  @apply bg-green/10 text-green;
}

body {
  font-family: 'Space Grotesk', sans-serif !important;
}

.slogan {
  animation: glitch 1s linear infinite;
  background: #000;
  color: #fff !important;
}

@keyframes glitch {
  2%,
  64% {
    transform: translate(2px, 0) skew(0deg);
  }
  4%,
  60% {
    transform: translate(-2px, 0) skew(0deg);
  }
  62% {
    transform: translate(0, 0) skew(5deg);
  }
}

.slogan:before,
.slogan:after {
  content: attr(title);
  position: absolute;
  left: 0;
}

.slogan:before {
  animation: glitchTop 1s linear infinite;
  clip-path: polygon(0 0, 100% 0, 100% 33%, 0 33%);
  -webkit-clip-path: polygon(0 0, 100% 0, 100% 33%, 0 33%);
}

@keyframes glitchTop {
  2%,
  64% {
    transform: translate(2px, -2px);
  }
  4%,
  60% {
    transform: translate(-2px, 2px);
  }
  62% {
    transform: translate(13px, -1px) skew(-13deg);
  }
}

.slogan:after {
  animation: glitchBotom 1.5s linear infinite;
  clip-path: polygon(0 67%, 100% 67%, 100% 100%, 0 100%);
  -webkit-clip-path: polygon(0 67%, 100% 67%, 100% 100%, 0 100%);
}

@keyframes glitchBotom {
  2%,
  64% {
    transform: translate(-2px, 0);
  }
  4%,
  60% {
    transform: translate(-2px, 0);
  }
  62% {
    transform: translate(-22px, 5px) skew(21deg);
  }
}

@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translate3d(0, 30%, 0);
  }

  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}
@keyframes fadeInUpBig {
  from {
    opacity: 0;
    transform: translate3d(0, 70%, 0);
  }

  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

@keyframes fadeInLeft {
  from {
    opacity: 0;
    transform: translate3d(-30%, 0, 0);
  }

  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}
@keyframes fadeInRight {
  from {
    opacity: 0;
    transform: translate3d(30%, 0, 0);
  }

  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

@keyframes fadeInRightCarousel {
  from {
    opacity: 0;
    transform: translate3d(30%, 0, 0);
    gap: 300px;
  }

  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

@keyframes stretchIn {
  from {
    opacity: 0;
    width: 70%;
    height: 90%;
  }

  to {
    opacity: 1;
    width: 100%;
    height: 100%;
  }
}

@keyframes zoomIn {
  from {
    opacity: 0;
    transform: scale(0);
  }

  to {
    opacity: 1;
    transform: scale(1);
  }
}

html,
body {
  margin: 0;
  height: 100%;
  overflow-x: hidden;
  background-color: #17171d;
}

.walletconnect-modal__header p {
  display: none !important;
}

.walletconnect-modal__header img {
  display: none !important;
}
